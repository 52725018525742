<template>
  <TabMenu
    :model="items"
    :active-index="0"
  />
  <router-view />
</template>

<script>

export default {
  components: { },
  inject: ['query'],
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    selectedProfile: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      items: [
        {
          label: 'Account',
          icon: 'pi pi-fw pi-user',
          to: { name: 'myAccountInfo', query: this.query },

        },
      ],
    };
  },
};
</script>
